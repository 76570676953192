const setVictoryOnPoints = (match, type) => {
    // По идее, если раундсов нет, то возвращаем пустую строку
    if (!match.rounds) return '';

    try {
        const victory_on_points = match.attributes.victory_on_points;
        const firstScore = match.rounds.attributes['first_score'].filter(item => item !== null).map(item => Number(item));
        const secondScore = match.rounds.attributes['second_score'].filter(item => item !== null).map(item => Number(item));

        // Если по какой-то причине у нас нет скоров, то возвращаем пустую строку
        if (!firstScore.length && !secondScore.length) return '';

        const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);

        // Если victory_on_points: true, то необходимо выводить сумму score для участников,
        // иначе нужно выводить количество выигранных раундов
        if (victory_on_points) {
            const sumFirstScore = firstScore.reduce(reducer)
            const sumSecondScore = secondScore.reduce(reducer)

            if (type === 'first') return sumFirstScore
            return sumSecondScore
        } else {
            let countOfWinnerFirst = 0;
            let countOfWinnerSecond = 0;

            for (let i = 0; i < firstScore.length; i++) {
                if (firstScore[i] > secondScore[i]) ++countOfWinnerFirst
                else if (firstScore[i] < secondScore[i]) ++countOfWinnerSecond
            }

            if (type === 'first') return countOfWinnerFirst
            return countOfWinnerSecond
        }
    } catch (e) {
        console.log(e)
        return ''
    }
}

export default setVictoryOnPoints;